import React from 'react';
import Layout from '../layouts/layout';
import SEO from '../components/seo';
import '../scss/_index.scss';
import Team from '../components/Team/Team';

const MeetTheTeamPage = () => (
  <Layout>
    <SEO title="Our Services" />
    <Team />
  </Layout>
);

export default MeetTheTeamPage;
