import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../../scss/team/_team.scss';

class Team extends Component {
  render() {
    const {
      teamData,
      drPatel,
      paige,
      shannon,
      vanessa,
      courtland,
    } = this.props;
    console.log(vanessa);
    return (
      <>
        <section className="team-title h-100">
          <Container fluid className="team-containter">
            <Row>
              <Col md={12} className="text-center team-sectionTitle">
                <h1>Meet The Team</h1>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="team-content h-100">
          <Container fluid className="team-containter">
            <Row>
              <Col md={4} className="text-center pt-5">
                <img
                  src={drPatel.childImageSharp.fluid.src}
                  alt="Dr. Mitul Patel"
                  className="team-image mx-auto shadow-lg "
                />
              </Col>
              <Col md={8} className="team-discription p-5">
                <div className="team-name pb-4">
                  <h1>{teamData[0]?.team.drName}</h1>
                  <h6>{teamData[0]?.team.drOccupation}</h6>
                </div>
                <p className="team-discription">
                  {teamData[0]?.team.drDiscriptionOne}
                </p>
                <p className="team-discription">
                  {teamData[0]?.team.drDiscriptionTwo}
                </p>
              </Col>
              <Col md={4} className="text-center py-5">
                <img
                  src={paige.childImageSharp.fluid.src}
                  alt="{Dr. Mitul Patel} Images"
                  className="team-image mx-auto shadow-lg "
                />
              </Col>
              <Col md={8} className="team-discription p-5">
                <div className="team-name pb-4">
                  <h1>{teamData[0]?.team.paigeName}</h1>
                  <h6>{teamData[0]?.team.paigeOccupation}</h6>
                </div>
                <p className="team-discription">
                  {teamData[0]?.team.paigeDiscriptionOne}
                </p>
                {/* <p className="team-discription">
                  {teamData[0]?.team.paigeDiscriptionTwo}
                </p> */}
              </Col>
              <Col md={4} className="text-center py-5">
                <img
                  src={shannon.childImageSharp.fluid.src}
                  alt="Shannon"
                  className="team-image mx-auto shadow-lg "
                />
              </Col>
              <Col md={8} className="team-discription p-5">
                <div className="team-name pb-4">
                  <h1>{teamData[0]?.team.shannonName}</h1>
                  <h6>{teamData[0]?.team.shannonOccupation}</h6>
                </div>
                <p className="team-discription">
                  {teamData[0]?.team.shannonDiscriptionOne}
                </p>
                <p className="team-discription">
                  {teamData[0]?.team.shannonDiscriptionTwo}
                </p>
              </Col>
              {console.log(vanessa.childImageSharp.fluid.src)}
              {/* <Col md={4} className="text-center py-5">
                <img
                  src={vanessa.childImageSharp.fluid.src}
                  alt="Vanessa"
                  className="team-image mx-auto shadow-lg "
                />
              </Col>
              <Col md={8} className="team-discription p-5">
                <div className="team-name pb-4">
                  <h1>{teamData[0]?.team.vanessaName}</h1>
                  <h6>{teamData[0]?.team.vanessaOccupation}</h6>
                </div>
                <p className="team-discription">
                  {teamData[0]?.team.vanessaDiscriptionOne}
                </p>
              </Col>
              <Col md={4} className="text-center py-5">
                <img
                  src={courtland.childImageSharp.fluid.src}
                  alt="Cortland"
                  className="team-image mx-auto shadow-lg "
                />
              </Col>
              <Col md={8} className="team-discription p-5">
                <div className="team-name pb-4">
                  <h1>{teamData[0]?.team.courtlandName}</h1>
                  <h6>{teamData[0]?.team.courtlandOccupation}</h6>
                </div>
                <p className="team-discription">
                  {teamData[0]?.team.courtlandDiscriptionOne}
                </p>
              </Col> */}
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

// export default Team;

// eslint-disable-next-line react/display-name
export default () => (
  <StaticQuery
    query={graphql`
      query TeamQuery {
        allTeamJson {
          nodes {
            team {
              drName
              drDiscriptionOne
              drDiscriptionTwo
              drOccupation
              paigeName
              paigeDiscriptionOne
              paigeDiscriptionTwo
              paigeOccupation
              shannonName
              shannonDiscriptionOne
              shannonOccupation
              vanessaName
              vanessaDiscriptionOne
              vanessaOccupation
              courtlandName
              courtlandDiscriptionOne
              courtlandOccupation
            }
          }
        }
        drPatel: file(relativePath: { eq: "team-photos/dr-patel.jpg" }) {
          childImageSharp {
            id
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        paige: file(relativePath: { eq: "team-photos/paige.jpg" }) {
          childImageSharp {
            id
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        shannon: file(relativePath: { eq: "team-photos/shannon.jpg" }) {
          childImageSharp {
            id
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        vanessa: file(relativePath: { eq: "team-photos/vanessa.jpg" }) {
          childImageSharp {
            id
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        courtland: file(relativePath: { eq: "team-photos/courtland.jpg" }) {
          childImageSharp {
            id
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data) => (
      <Team
        teamData={data.allTeamJson.nodes}
        drPatel={data.drPatel}
        paige={data.paige}
        shannon={data.shannon}
        vanessa={data.vanessa}
        courtland={data.courtland}
      />
    )}
  />
);

Team.propTypes = {
  teamData: PropTypes.array.isRequired,
  drPatel: PropTypes.object.isRequired,
  paige: PropTypes.object.isRequired,
  shannon: PropTypes.object.isRequired,
  vanessa: PropTypes.object.isRequired,
  courtland: PropTypes.object.isRequired,
};
